export type CmpLoadStatus = {
    gdprApplies: boolean,
    eventStatus: 'tcloaded'|'useractioncomplete'|string
}

export type CmpController = ((command: 'addEventListener'|'removeEventListener', commandId: 2, callback: (loadStatus: CmpLoadStatus, success: boolean) => void) => void | undefined)

export type QmnAdConfig = {id: number, width: number, height: number};

export const QmnAds: {[key: string]: QmnAdConfig} = {
    DesktopPageTracker: {
        id: 2067,
        width: 1,
        height: 1
    },
    DesktopSitebarAd: {
        id: 2063,
        width: 300,
        height: 601
    },
    DesktopIntextAd: {
        id: 2066,
        width: 800,
        height: 250
    },
    DesktopFloorAd: {
        id: 2064,
        width: 301,
        height: 600
    },
    DesktopProspektAd: {
        id: 2065,
        width: 800,
        height: 800
    },

    MobilePageTracker: {
        id: 2073,
        width: 1,
        height: 1
    },
    MobileFloorAd: {
        id: 2070,
        width: 302,
        height: 600
    },
    MobileHPATopAreaAd: {
        id: 2072,
        width: 300,
        height: 600
    },
    MobileHPABottomAreaAd: {
        id: 2069,
        width: 300,
        height: 600
    },
    MobileProspektAd: {
        id: 2071,
        width: 801,
        height: 800
    },
}